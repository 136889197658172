import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const firebaseconfig = {
  apiKey: "AIzaSyBNwW42HaeEMn7WHFth386b__I5m50FrSg",
  authDomain: "hd-testing-e1b71.firebaseapp.com",
  projectId: "hd-testing-e1b71",
  storageBucket: "hd-testing-e1b71.firebasestorage.app",
  messagingSenderId: "1064335564851",
  appId: "1:1064335564851:web:67f44cb457373f667d4346",
  measurementId: "G-SFGXR44C1G",
};

// initialize firebase
const app = initializeApp(firebaseconfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);

if (process.env.REACT_APP_DEBUG === "true") {
  console.log("Running on DEBUG mode");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(db, "localhost", 8081);
  connectStorageEmulator(storage, "localhost", 9199);
} else {
  console.log("Running on PRODUCTION mode");
}
