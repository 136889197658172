import React from "react";

const EmailInput = ({ email, setEmail, error }) => {
  return (
    <div>
      <label className="block text-left text-gray-700 mb-2">Email</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full px-3 py-2 border rounded mb-3 bg-slate-300"
        placeholder="you@example.com"
      />
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
};

export default EmailInput;
