import { functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";

export const PuzzleService = {
  getColorPuzzle: async (color, latitude, longitude) => {
    const getColorPuzzle = httpsCallable(functions, "GetColorPuzzle");

    return getColorPuzzle({ color, latitude, longitude })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },

  validateColorPattern: async (pattern, sessionId) => {
    const validateColorPattern = httpsCallable(
      functions,
      "ValidateColorPattern",
    );

    return validateColorPattern({ attempt: pattern, sessionId: sessionId })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
        return false;
      });
  },

  verifyColorCompletion: async (validationKey) => {
    const getColorPuzzle = httpsCallable(functions, "VerifyPuzzleCompletion");

    return getColorPuzzle({ key: validationKey })
      .then((result) => {
        return result.data.verified;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },

  submitFormData: async (formData) => {
    const submitFormData = httpsCallable(functions, "SubmitFormData");
    return submitFormData(formData)
      .then((result) => {
        return result.data.success;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },

  loadThumbnailInformation: async () => {
    const submitFormData = httpsCallable(functions, "FetchPuzzleInformation");
    return submitFormData()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },

  loadPuzzleDetailInformation: async (key) => {
    const submitFormData = httpsCallable(
      functions,
      "FetchPuzzleDetailInformation",
    );
    return submitFormData({ id: key })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },
};
