import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./LocationPermission.css";
import { UtilityService } from "../../services/UtilityService";
import {
  Dialogue,
  Message,
  TextModifier,
} from "../../components/dialogue/Dialogue";

export function LocationPermission() {
  const { state, search } = useLocation();
  const geolocation = navigator.geolocation;
  const navigate = useNavigate();
  const [puzzleQueryParam, setPuzzleQueryParam] = useState(null);
  const [messages, setMessages] = useState([
    new Message("this challenge requires your permission"),
  ]);

  /*** Animation properties ***/
  const [isGrantButtonShowing, setIsGrantButtonShowing] = useState(false);

  // Helper function to handle denied permissions
  const handlePermissionDenied = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isChrome = userAgent.includes("CriOS");
    setMessages([
      new Message("you have denied permissions previously"),
      new Message(
        "you must enable location settings for this website in your settings",
      ),
      new Message(
        isChrome
          ? "Chrome > Settings > Privacy and Security > Site Settings > Location"
          : "Safari > Preferences > Websites > Location",
        TextModifier.italic,
      ),
    ]);

    setIsGrantButtonShowing(false);
  };

  // Helper function to verify user location
  const verifyUserLocation = async (latitude, longitude, puzzleQueryParam) => {
    try {
      const isInRange = await UtilityService.isInRange(
        latitude,
        longitude,
        state.puzzle,
      );
      if (!isInRange) {
        // Show "you are not in the challenge radius"
      } else {
        navigate(`/🧩/${puzzleQueryParam}`);
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to verify user location: ",
        error,
      );
    }
  };

  // Helper function to handle geolocation errors
  const handleGeolocationError = (error) => {
    console.error(
      "An error occurred while trying to fetch user location: ",
      error,
    );
    if (error.code === 1) {
      // User denied
      handlePermissionDenied();
    }
  };

  // Main function
  const promptUserPermissions = (event) => {
    event.preventDefault();

    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "denied") {
        handlePermissionDenied();
      } else {
        geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            verifyUserLocation(latitude, longitude, puzzleQueryParam);
          },
          (error) => handleGeolocationError(error),
        );
      }
    });
  };

  useEffect(() => {
    const queryPuzzle = new URLSearchParams(search).get("puzzle");
    if (!state?.puzzle || !queryPuzzle) navigate("/NotFound");

    const timer1 = setTimeout(() => setIsGrantButtonShowing(true), 1500);
    setPuzzleQueryParam(queryPuzzle);

    return () => {
      clearTimeout(timer1);
    };
  }, [navigate, search, state]);

  if (!geolocation) {
    return (
      <div className="flex flex-col size-full justify-center items-center">
        <p
          id="location-incompatible-device-text"
          className={`text-center text-display-sm ${isGrantButtonShowing ? "visible" : ""}`}
        >
          🫵📱🚫📍📡
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col size-full justify-center items-center gap-4">
      <div className="h-12">
        <Dialogue messages={messages} />
      </div>
      <button
        disabled={!isGrantButtonShowing}
        onClick={promptUserPermissions}
        className={`text-title-lg .directive ${isGrantButtonShowing ? "showing" : ""}`}
      >
        Grant?
      </button>
    </div>
  );
}
