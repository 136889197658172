import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialogue, Message, TextModifier } from "./dialogue/Dialogue";

export function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/🌅", { replace: true });
  }, [navigate]);

  const messages = [
    new Message("🌅", TextModifier.large, 500),
    new Message("wander and wonder together", TextModifier.italic, 800),
  ];

  return <Dialogue messages={messages} />;
}
