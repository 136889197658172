export const Color = Object.freeze({
  RED: "bg-red-700",
  BLUE: "bg-blue-700",
  PURPLE: "bg-violet-700",
  NONE: "bg-slate-400",
});

export const convertColorsToKey = (colors) => {
  return colors
    .map((value) => {
      return Object.keys(Color)
        .find((key) => Color[key] === value)
        .toLowerCase()
        .at(0);
    })
    .join("");
};

export const convertKeyToColors = (names) => {
  return names.split("").map((color) => {
    switch (color) {
      case "r":
        return Color.RED;
      case "b":
        return Color.BLUE;
      case "p":
        return Color.PURPLE;
      default:
        return Color.NONE;
    }
  });
};

export function ColorRectangle({ color, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`${color} ${color === Color.NONE ? "untapped" : ""} color-rectangle`}
    ></div>
  );
}
