import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialogue, Message } from "../../components/dialogue/Dialogue";

export function NotInRange() {
  const navigate = useNavigate();
  const messages = useMemo(
    () => [new Message("you're not in range for this interaction")],
    [],
  );
  const [showNavigationButton, setShowNavigationButton] = useState(false);

  useEffect(() => {
    let timerId = setTimeout(() => {
      setShowNavigationButton(true);
    }, 2500);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <div className="page-container justify-center">
      <div>
        <Dialogue messages={messages} />
      </div>

      <button
        id="grant-permissions"
        disabled={!showNavigationButton}
        onClick={() => navigate("/🗺️/")}
        className={`text-title-lg directive ${showNavigationButton ? "showing" : ""}`}
      >
        Discover waypoints
      </button>
    </div>
  );
}
