import { ColorRectangle, Color, convertColorsToKey } from "./ColorRectangle";
import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { PuzzleService } from "../../../../services/PuzzleService";
import { useNavigate } from "react-router-dom";
import { doc } from "firebase/firestore";
import { db } from "../../../../config/firebase";

const colorCycle = [Color.RED, Color.BLUE, Color.PURPLE, Color.NONE];

const checkIfInCurrentCompletionQueue = (snapshot, sessionId) => {
  const correctQueue = snapshot.get("correct_queue") || [];
  return correctQueue.includes(sessionId);
};

export function ColorPuzzleGrid({
  cluePattern,
  sessionId,
  lobbyId,
  setCluePattern,
  fetchNewPattern,
}) {
  const [userGuess, setUserGuess] = useState(Array(6).fill(Color.NONE));
  const [userSubmission, setUserSubmission] = useState([]);
  const [isCheckingGuess, setIsCheckingGuess] = useState(false);
  const [isUserInCorrectQueue, setIsUserInCorrectQueue] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const lobbyDoc = doc(db, `lobbies/${lobbyId}`);
  const [snapshot, , listenerError] = useDocument(lobbyDoc);

  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(null), 2000);
  };

  /*** Navigating to success page ***/
  useEffect(() => {
    if (!snapshot?.exists()) return;

    let didCancel = false;

    const userInCurrentCompleteQueue = checkIfInCurrentCompletionQueue(
      snapshot,
      sessionId,
    );

    const isPuzzleComplete = snapshot.get("is_puzzle_complete");
    const key = snapshot.get("key") + convertColorsToKey(userSubmission);

    const refreshPuzzle = async () => {
      let pattern = await fetchNewPattern();
      if (pattern && !didCancel) setCluePattern(pattern);
    };

    if (isPuzzleComplete) {
      if (!userInCurrentCompleteQueue) {
        refreshPuzzle();
        return;
      }

      navigate("/🧩/🟣", {
        state: { validationKey: key },
      });
    }

    return () => {
      didCancel = true;
    };
  }, [
    snapshot,
    navigate,
    userSubmission,
    sessionId,
    fetchNewPattern,
    setCluePattern,
  ]);

  /*** React to submission timeouts ***/
  useEffect(() => {
    if (!snapshot?.exists) return;

    const inCurrentCompletionQueue = checkIfInCurrentCompletionQueue(
      snapshot,
      sessionId,
    );

    if (inCurrentCompletionQueue !== isUserInCorrectQueue) {
      if (inCurrentCompletionQueue) {
        setIsUserInCorrectQueue(true);
      } else if (isUserInCorrectQueue) {
        showError("🔴⏰ ≠ 🔵⏰");
        setIsCheckingGuess(false);
        setIsUserInCorrectQueue(false);
      }
    }
  }, [snapshot, sessionId, isUserInCorrectQueue]);

  /*** React to snapshot errors ***/
  useEffect(() => {
    if (listenerError) console.error(listenerError.message);
  }, [listenerError]);

  const handleTap = (index) => {
    setUserGuess((prevElements) => {
      const currentColor =
        prevElements[index] === Color.NONE ? Color.PURPLE : prevElements[index];
      const nextColorIndex =
        (colorCycle.indexOf(currentColor) + 1) % (colorCycle.length - 1);
      const newElements = [...prevElements];
      newElements[index] = colorCycle[nextColorIndex];

      return newElements;
    });
  };

  const canSubmit = () => {
    return (
      userGuess.every((el) => el !== Color.NONE) && !error && !isCheckingGuess
    );
  };

  const validate = async () => {
    if (userGuess.find((el) => el === Color.NONE)) return false;

    const colorKeys = convertColorsToKey(userGuess);
    setIsCheckingGuess(true);
    setUserSubmission(userGuess);
    return await PuzzleService.validateColorPattern(colorKeys, sessionId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = await validate();

    if (validationResult.message !== "OK") {
      showError(validationResult.message);
      setIsCheckingGuess(false);
    }
  };

  return (
    <>
      <div
        id="color-puzzle-box"
        className={`m-auto bg-slate-100 relative ${error ? "error-flash" : ""}`}
      >
        {error && (
          <div
            id="color-error-overlay"
            className="absolute top-0 left-0 size-full flex flex-col justify-center"
          >
            <p className="text-center text-headline-sm">{error}</p>
          </div>
        )}
        {cluePattern.map((el, i) => (
          <ColorRectangle key={i} color={el} />
        ))}

        {userGuess.map((el, i) => (
          <ColorRectangle onClick={() => handleTap(i)} key={i} color={el} />
        ))}
      </div>

      <button
        disabled={!canSubmit()}
        className={`btn btn-blue w-[135px] shadow-lg ${isCheckingGuess ? "blinking" : ""}`}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </>
  );
}
