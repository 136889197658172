import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Dialogue } from "../../components/dialogue/Dialogue";
import { Pulse } from "../../components/pulse/Pulse";
import { storage } from "../../config/firebase";
import { PuzzleService } from "../../services/PuzzleService";
import { PuzzleThumbnail } from "./components/PuzzleThumbnail";
import { Message } from "../../components/dialogue/Dialogue";
import { PuzzleMap } from "../../components/PuzzleMap";
import "./Atlas.css";

export function AtlasDetail() {
  const { puzzle } = useParams();
  const { state } = useLocation();
  const [validLocations, setValidLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isChangingImages, setIsChangingImages] = useState(false);
  const [showInteractions, setShowInteractions] = useState(false);

  const changePin = (el) => {
    if (el === selectedLocation) return;

    setIsChangingImages(true);

    setTimeout(() => {
      setSelectedLocation(el);
    }, 500);

    setTimeout(() => {
      setIsChangingImages(false);
    }, 1000);
  };

  const messages = useMemo(
    () => [
      new Message("find the spots where you can play"),
      new Message(
        'look for <img className="inline-block w-8 h-8" src="/favicon.ico" /> to guide your way',
      ),
    ],
    [],
  );

  useEffect(() => {
    let timerId = setTimeout(() => {
      setShowInteractions(true);
    }, 3000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    let didCancel = false;

    if (!state?.puzzleId) {
      console.warn("No puzzle id given -- will fail to fetch server data");
      return;
    }

    // Fetch puzzle data from server
    const fetchData = async () => {
      const result = await PuzzleService.loadPuzzleDetailInformation(
        state.puzzleId,
      );

      if (!result || result.length === 0) {
        console.warn("The server had no data for this puzzle");
        return;
      }

      const resultWithValidUrls = await Promise.all(
        result.map(async (item) => {
          const imagePath = ref(storage, item.imagePath);
          const url = await getDownloadURL(imagePath);
          return { ...item, imagePath: url };
        }),
      );

      if (!didCancel) {
        setValidLocations(resultWithValidUrls);
      }
    };

    fetchData();
    return () => {
      didCancel = true;
    };
  }, [state.puzzleId]);

  return (
    <div className="flex flex-col items-center px-8 py-4 size-full gap-4">
      <h1 className="text-display-lg text-center">{puzzle}</h1>
      <Dialogue messages={messages} />
      {showInteractions && validLocations ? (
        <div className="fade-in size-full">
          <div className="flex flex-col size-full gap-4">
            <div
              className={`w-full flex-1 puzzle-location-image flex-1 ${isChangingImages ? "animating" : ""}`}
            >
              <PuzzleThumbnail
                src={selectedLocation?.imagePath}
                title={selectedLocation?.description}
              />
            </div>
            <PuzzleMap
              handlePinClick={(newLocation) => {
                changePin(newLocation);
              }}
              locations={validLocations}
            />
          </div>
        </div>
      ) : (
        <div className="relative size-full flex-2">
          <Pulse />
        </div>
      )}
    </div>
  );
}
