import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialogue,
  Message,
  Transition,
  TransitionType,
} from "../../components/dialogue/Dialogue";

export function LocationRequirement({ puzzle, setLocation }) {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([
    new Message("this challenge requires your location"),
    new Transition(TransitionType.fadeOut, 2000),
  ]);
  const [isPromptingUser, setIsPromptingUser] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsPromptingUser(true), 3800);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isPromptingUser) return;

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      },
      (error) => {
        if (error.code === 2) {
          setMessages([
            new Message("unable to retrieve your location"),
            new Message("check your connection and refresh"),
          ]);
          return;
        }

        console.error("Failed to get location data. Error: ", error.message);
        navigate(`/📍?puzzle=${puzzle}`, { state: { puzzle: puzzle } });
      },
    );
  }, [setLocation, navigate, puzzle, isPromptingUser]);

  return <Dialogue messages={messages} />;
}
