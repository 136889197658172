import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { CarouselButton, Orientation } from "./CarouselButton";
import { useEffect, useState } from "react";

export function CarouselControl({ setVisibleElement, totalItems }) {
  const [currentIndex, setCurrentIndex] = useState([]);

  useEffect(() => {
    setCurrentIndex(
      totalItems === 0 ? [] : [true, ...Array(totalItems - 1).map(() => false)],
    );
  }, [totalItems]);

  const handleClick = (isLeftClick) => {
    setCurrentIndex((prev) => {
      const prevIndex = prev.indexOf(true);
      let nextIndex;

      if (isLeftClick) {
        nextIndex = prevIndex === 0 ? totalItems - 1 : prevIndex - 1;
      } else {
        nextIndex = (prevIndex + 1) % totalItems;
      }
      const newArray = Array(totalItems).fill(false);
      newArray[nextIndex] = true;
      return newArray;
    });
  };

  return (
    <div className="w-full flex justify-around gap-8 items-center bg-slate-grey">
      <CarouselButton
        orientation={Orientation.LEFT}
        onClick={() => handleClick(true)}
      />
      <div className="flex gap-4">
        {currentIndex.map((el, index) => {
          return (
            <FontAwesomeIcon
              key={index}
              icon={faCircle}
              className={`size-2 ${el ? "text-white" : "text-slate-600"}`}
            />
          );
        })}
      </div>
      <CarouselButton
        orientation={Orientation.RIGHT}
        onClick={() => handleClick(false)}
      />
    </div>
  );
}
