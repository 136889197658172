import { Map, Marker } from "mapkit-react";

export function PuzzleMap({ handlePinClick, locations }) {
  const initialRegion = {
    centerLatitude: 41.7896,
    centerLongitude: -87.59967,
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
  };

  return (
    <div className="flex-1 w-full border-4 border-white/75 shadow-md rounded">
      <Map
        showsPointsOfInterest={false}
        initialRegion={initialRegion}
        token="eyJraWQiOiJINVY2OFBDSjdZIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJNRDZISDhLOUw2IiwiaWF0IjoxNzMxOTM5ODY2LCJvcmlnaW4iOiIqLmRvdGRhc2g2ZG90LmNvbSJ9.3vFZ03QpKbIEa0Ix7IviJIgmSKF1Rv2aZlKJ7Y0tl_iM4FWrdnvSEWjDBkPCcGEKxOjOhDZFm-JFPVZGqExpPg"
      >
        {locations.map((el) => (
          <Marker
            key={el.id}
            onSelect={() => {
              handlePinClick(el);
            }}
            latitude={el.latitude}
            longitude={el.longitude}
          />
        ))}
      </Map>
    </div>
  );
}
