import "./Pulse.css";

export function Pulse() {
  return (
    <div className="size-full relative">
      <div
        id="pulse-circle"
        className="absolute translate-x-[-50%] inset-1/2 size-6"
      ></div>
    </div>
  );
}
