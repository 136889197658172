import { useNavigate } from "react-router-dom";
import React from "react";

export function PuzzleThumbnail({ puzzleId, src, title, slug }) {
  const navigate = useNavigate();

  const navigateToPuzzleDetail = (e) => {
    e.preventDefault();

    if (!slug) return;

    navigate(`/🗺️/${slug}`, { state: { puzzleId: puzzleId } });
  };

  return (
    <div className="size-full rounded-lg border-4 border-white/70 shadow-lg relative">
      <div
        className="size-full flex flex-col relative"
        onClick={navigateToPuzzleDetail}
      >
        {src ? (
          <img src={src} alt="" className="object-fit shaded size-full" />
        ) : (
          <div className="w-full text-headline-lg text-white text-center z-20 absolute top-1/2 translate-y-[-50%]">
            Choose a pin
          </div>
        )}
        <div className="image-shader w-full absolute inset-0"></div>
      </div>
      <h1 className="text-headline-lg top-1/2 left-1/2 text-white absolute inline-block translate-x-[-50%] translate-y-[-50%] z-10">
        {title}
      </h1>
    </div>
  );
}
