import React, { useState } from "react";
import VenmoInput from "./VenmoInput";
import InterestSelection from "../../pages/puzzles/color/components/InterestSelection";
import EmailInput from "./EmailInput";

export function PuzzleCompleteForm({ onSubmit, isSubmitting }) {
  const [venmo, setVenmo] = useState("");
  const [isInterested, setIsInterested] = useState(null);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!venmo.replace("@", "")) newErrors.venmo = "Venmo is required";
    if (isInterested === null)
      newErrors.isInterested = "Interest selection is required";
    if (isInterested && !emailRegex.test(email))
      newErrors.email = "Email is required if interested";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      onSubmit({ venmo, email });
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`bg-slate-100 p-6 rounded-lg w-[90%] max-w-[400px] shadow-lg text-center m-auto flex flex-col gap-4`}
      id="contact-submission-form"
    >
      <VenmoInput venmo={venmo} setVenmo={setVenmo} error={errors.venmo} />
      <InterestSelection
        isInterested={isInterested}
        setIsInterested={setIsInterested}
        error={errors.isInterested}
      />
      {isInterested && (
        <EmailInput email={email} setEmail={setEmail} error={errors.email} />
      )}
      <button
        className={`btn btn-blue w-[150px] mx-auto ${isSubmitting ? ".blinking" : ""}`}
      >
        Submit
      </button>
    </form>
  );
}
