import React, { useEffect, useMemo, useState } from "react";
import { CarouselControl } from "../../components/carousel/CarouselControl";
import { Dialogue, Message } from "../../components/dialogue/Dialogue";
import { PuzzleService } from "../../services/PuzzleService";
import { PuzzleThumbnail } from "./components/PuzzleThumbnail";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase";

export function Atlas() {
  const [errorMessage, setErrorMessage] = useState(null);
  const memoMessages = useMemo(
    () => [
      new Message("in each challenge, a secret hides"),
      new Message("complete in twos to win a prize"),
    ],
    [],
  );

  const [puzzleData, setPuzzleData] = useState([]);
  const [showPuzzles, setShowPuzzles] = useState(false);

  useEffect(() => {
    let timerId = setTimeout(() => {
      setShowPuzzles(true);
    }, 2500);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    let didCancel = false;

    const fetchPuzzleData = async () => {
      const result = await PuzzleService.loadThumbnailInformation();

      if (!result || result.length === 0) {
        setErrorMessage([
          new Message("a server error has occurred"),
          new Message("try again later"),
        ]);
        console.log("Server error -- try again later");
        return;
      }

      const completeResult = await Promise.all(
        result.map(async (item) => {
          const thumbnailRef = ref(storage, item.thumbnail);
          const url = await getDownloadURL(thumbnailRef);
          return { ...item, thumbnail: url };
        }),
      );

      if (!didCancel) setPuzzleData(completeResult);
    };

    fetchPuzzleData();
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <>
      {errorMessage ? (
        <Dialogue messages={errorMessage} />
      ) : (
        <div className="size-full flex flex-col items-center gap-8 justify-around my-4 px-8">
          <h1 className="text-display-lg">🗺️</h1>
          <Dialogue messages={memoMessages} />
          <div
            className={`opacity-0 transition duration-1000 ease-in-out flex flex-col items-center justify-evenly size-full ${showPuzzles ? "opacity-100" : ""}`}
          >
            {puzzleData.map((el) => (
              <div key={el.key} className="aspect-square">
                <PuzzleThumbnail
                  puzzleId={el.id}
                  slug={el.slug}
                  src={el.thumbnail}
                  title={el.title}
                />
              </div>
            ))}
            <CarouselControl totalItems={puzzleData.length} />
          </div>
        </div>
      )}
    </>
  );
}
