import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Dialogue,
  Message,
  TextModifier,
} from "../../../components/dialogue/Dialogue";
import { PuzzleService } from "../../../services/PuzzleService";
import { PuzzleCompleteForm } from "../../../components/forms/PuzzleCompleteForm";
import "./ColorPuzzle.css";

export function ColorSuccess() {
  const [didCompletePuzzle, setDidCompletePuzzle] = useState(false);
  const [didSubmit, setDidSubmit] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHeaderShowing, setIsHeaderShowing] = useState(false);
  const [isFormShowing, setIsFormShowing] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const successMessages = [
    new Message("You will receive your payment shortly", undefined, 500),
    new Message("wander and wonder together", TextModifier.italic, 1500),
  ];

  useEffect(() => {
    const timer1 = setTimeout(() => setIsHeaderShowing(true), 500);
    const timer2 = setTimeout(() => setIsFormShowing(true), 2000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    const validateRequest = async () => {
      const isValid = await PuzzleService.verifyColorCompletion(
        state.validationKey,
      );
      if (!isValid) navigate("/NotFound");
      setDidCompletePuzzle(true);
    };

    validateRequest();
  }, [state, navigate]);

  const handleSubmit = async (formData) => {
    setIsSubmitting(true);
    await PuzzleService.submitFormData({
      ...formData,
      key: state.validationKey,
    });
    setDidSubmit(true);
    setIsHeaderShowing(false);
    setIsSubmitting(false);
  };

  if (!didCompletePuzzle) return <></>;
  if (didSubmit) return <Dialogue messages={successMessages} />;

  return (
    <>
      <h1
        className={`text-display-lg my-6 text-slate-100 text-center ${isHeaderShowing ? "visible" : ""}`}
        id="form-success-header"
      >
        Success
      </h1>
      {isFormShowing && (
        <div className="grow flex">
          <PuzzleCompleteForm
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </div>
      )}
    </>
  );
}
