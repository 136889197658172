import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import "./Dialogue.css";

export const TextModifier = Object.freeze({
  italic: "italic",
  large: "text-display-lg",
  none: "",
});

export const TransitionType = Object.freeze({
  fadeOut: "fade-out",
});

export class Message {
  constructor(content, modifier = TextModifier.none, delay = 1000) {
    this.content = content;
    this.modifier = modifier;
    this.delay = delay;
  }
}

export class Transition {
  constructor(type = TransitionType.fadeOut, delay = 1000) {
    this.type = type;
    this.delay = delay;
  }
}

export function Dialogue({ messages }) {
  const [fadeOut, setFadeOut] = useState(false);

  const [visibleMessages, setVisibleMessages] = useState([]);

  const [nextMessageIndex, setNextMessageIndex] = useState(0);

  /*** PREPARE FOR NEW MESSAGES ***/
  useEffect(() => {
    // ignore resetting messages until all animations have played
    if (!visibleMessages.every((el) => el.isShowing)) return;

    if (visibleMessages.length !== 0) {
      setVisibleMessages((prev) =>
        prev.map((el) => {
          return { ...el, isShowing: false };
        }),
      );

      setTimeout(() => {
        setVisibleMessages(
          messages.map((message) => ({
            ...message,
            isShowing: false,
          })),
        );
        setNextMessageIndex(0);
      }, 800);
    } else {
      setVisibleMessages(
        messages.map((message) => ({
          ...message,
          isShowing: false,
        })),
      );
      setNextMessageIndex(0);
    }
  }, [messages, visibleMessages]);

  useEffect(() => {
    let timeoutId;

    const showNewElement = (prev) => {
      const toShow = prev[nextMessageIndex];

      toShow.isShowing = true;
      let newArr = prev;
      newArr[nextMessageIndex] = toShow;

      return newArr;
    };

    if (nextMessageIndex < messages.length) {
      timeoutId = setTimeout(() => {
        if (messages[nextMessageIndex] instanceof Transition) {
          setFadeOut(true);
        }

        setVisibleMessages((prev) => showNewElement(prev));
        setNextMessageIndex((prev) => prev + 1);
      }, messages[nextMessageIndex].delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [messages, nextMessageIndex]);

  return (
    <div className="flex grow flex-col justify-center items-center gap-4">
      {visibleMessages.map((message, index) => (
        <p
          key={index}
          className={`
            ${message.modifier}
            ${fadeOut ? "!opacity-0" : ""}
            ${message.isShowing ? "showing" : ""}
            body-dialogue
            `}
        >
          {parse(message.content || "")}
        </p>
      ))}
    </div>
  );
}
