import React from "react";

const InterestSelection = ({ isInterested, setIsInterested, error }) => {
  return (
    <div>
      <p className="my-4 text-gray-700 text-left">Want more like this?</p>
      <div className="flex justify-around mb-4">
        <label className="flex items-center">
          <input
            type="radio"
            name="interested"
            value="yes"
            onChange={() => setIsInterested(true)}
            className="mr-2"
          />
          Yes
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            name="interested"
            value="no"
            onChange={() => setIsInterested(false)}
            className="mr-2"
          />
          No
        </label>
      </div>
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
};

export default InterestSelection;
