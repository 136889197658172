import React from "react";

const VenmoInput = ({ venmo, setVenmo, error }) => {
  const handleVenmoChange = (e) => {
    const value = e.target.value;
    setVenmo(value.startsWith("@") ? value : "@" + value);
  };

  return (
    <div>
      <label className="block text-left text-gray-700 mb-2">Venmo</label>
      <input
        type="text"
        value={venmo}
        onChange={handleVenmoChange}
        className="w-full px-3 py-2 border rounded mb-3 bg-slate-300"
        placeholder="@yourvenmo"
      />
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
};

export default VenmoInput;
