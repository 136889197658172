import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Orientation = Object.freeze({
  LEFT: 270,
  RIGHT: 90,
});

export function CarouselButton({ orientation, onClick }) {
  return (
    <>
      <div
        className="bg-blue-600 rounded-full relative size-8 active:bg-blue-800"
        onClick={onClick}
      >
        <FontAwesomeIcon
          rotation={orientation}
          icon={faArrowUp}
          className="absolute top-1/4 inset-x-1/3 text-white"
        />
      </div>
    </>
  );
}
