import { useState, useEffect, useCallback, useMemo } from "react";
import { Message, Dialogue } from "../../../components/dialogue/Dialogue";
import { LocationRequirement } from "../../location/LocationRequirement";
import { PuzzleService } from "../../../services/PuzzleService";
import { ColorPuzzleGrid } from "./components/ColorPuzzleGrid";
import { Color, convertKeyToColors } from "./components/ColorRectangle";
import "./ColorPuzzle.css";
import { Pulse } from "../../../components/pulse/Pulse";
import { NotInRange } from "../../location/NotInRange";

export function ColorChallenge({ color }) {
  const [cluePattern, setCluePattern] = useState(Array(6).fill(Color.NONE));
  const [isFetchingPuzzle, setIsFetchingPuzzle] = useState(true);
  const [isWithinRange, setIsWithinRange] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [location, setLocation] = useState(null);
  const [lobby, setLobby] = useState(null);

  const puzzleMessages = useMemo(
    () => [
      new Message("Combine your halves to make the hue", undefined, 0),
      new Message("Committing in lockstep is the clue"),
    ],
    [],
  );

  /*** Animation logic ***/
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  const renderComponent = () => {
    if (!location) {
      return <LocationRequirement puzzle={"color"} setLocation={setLocation} />;
    } else if (isFetchingPuzzle) {
      return <Pulse />;
    } else if (!isWithinRange) {
      return <NotInRange />;
    } else {
      return (
        <>
          <h1
            className={`text-display-lg text-center mb-12 ${step1 ? "visible" : ""}`}
            id="color-emoji-header"
          >
            {color}
          </h1>
          <div className="flex flex-col gap-12 justify-evenly items-center h-[70%]">
            {step2 && <Dialogue messages={puzzleMessages} />}

            <div
              id="color-puzzle-grid"
              className={`size-full flex flex-col items-center gap-12 ${step3 ? "visible" : ""}`}
            >
              <ColorPuzzleGrid
                cluePattern={cluePattern}
                sessionId={sessionId}
                lobbyId={lobby}
                setCluePattern={setCluePattern}
                fetchNewPattern={fetchNewPattern}
              />
            </div>
          </div>
        </>
      );
    }
  };

  const fetchNewPattern = useCallback(async () => {
    if (!location) return;

    let result =
      (await PuzzleService.getColorPuzzle(
        color,
        location.latitude,
        location.longitude,
      )) || Array(6).fill(Color.NONE);

    setIsFetchingPuzzle(false);

    // Check if within range
    if (!result.withinRange) return;

    setIsWithinRange(true);
    setLobby(result.lobbyId);
    setSessionId(result.sessionId);
    return convertKeyToColors(result.pattern);
  }, [color, location]);

  /*** SYNCING ANIMATIONS ***/
  useEffect(() => {
    if (!location) return;

    const timer1 = setTimeout(() => setStep1(true), 500);
    const timer2 = setTimeout(() => setStep2(true), 1500);
    const timer3 = setTimeout(() => setStep3(true), 3500);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [location]);

  useEffect(() => {
    let didCancel = false;

    const refreshPuzzle = async () => {
      let pattern = await fetchNewPattern();
      if (pattern && !didCancel) setCluePattern(pattern);
    };

    refreshPuzzle();
    return () => {
      didCancel = true;
    };
  }, [fetchNewPattern]);

  return <div className="p-4 size-full flex flex-col">{renderComponent()}</div>;
}
